import React from "react";
import { css } from "@emotion/core";
import addToMailchimp from "gatsby-plugin-mailchimp";
import Modal from "react-modal";
import theme from "../constants/theme";

import Link from "./Link";
import Text from "./Text";
import StyledInput from "../styles/StyledInput";
import StyledLink from "./StyledLink";
import { MdMailOutline as MailIcon, MdClose as CloseIcon } from "react-icons/md";
import { FaRegCheckCircle as CheckIcon } from "react-icons/fa";

Modal.setAppElement(`#___gatsby`);

export default function ReactModal({ isOpen, closeModal }) {
  const [submitSuccessful, setSubmitStatus] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [college, setCollege] = React.useState("");

  // const _handleSubmit = e => {
  //   e.preventDefault();
  //   addToMailchimp(email, {
  //     FNAME: firstName,
  //     LNAME: lastName,
  //     MMERGE4: college
  //   }).then(data => {
  //     setSubmitStatus(true);
  //     // console.log(data);
  //   });
  // };
  const _handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      console.log("Submitting to MailChimp...");
      
      // Add timeout handling
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 seconds timeout
  
      const result = await addToMailchimp(email, {
        FNAME: firstName,
        LNAME: lastName,
        MMERGE3: college
      });
  
      clearTimeout(timeoutId); // Clear timeout if request succeeds
  
      if (result.result === "success") {
        setSubmitStatus(true);
      } else {
        alert(`Error: ${result.msg}`);
      }
    } catch (error) {
      console.error("Mailchimp Error:", error);
  
      if (error.name === "AbortError") {
        alert("Request timed out. Please try again.");
      } else {
        alert("There was an error submitting the form.");
      }
    }
  };
  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
      htmlOpenClassName="ReactModal__Html--open"
      style={{
        overlay: {
          position: `fixed`,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: `rgba(0, 0, 0, 0.75)`,
          zIndex: `${theme.z.contactFormModal}`
        },
        content: {
          position: `absolute`,
          border: `none`,
          background: `white`,
          width: 1000,
          height: "auto",
          maxWidth: "90%",
          maxHeight: "90%",
          left: `50%`,
          top: `50%`,
          right: "unset",
          bottom: "unset",
          transform: `translate(-50%, -50%)`,
          overflow: `auto`,
          WebkitOverflowScrolling: `touch`,
          padding: 0
        }
      }}
    >
      <div>
        <div
          className="container"
          css={css`
            padding: 20px;
          `}
        >
          <div className="row justify-content-center">
            <div className="col-md-10">
              <Text
                h3
                css={css`
                  padding-top: ${theme.margin.small};
                `}
              >
                Join
              </Text>
              <hr
                css={css`
                  border-color: ${theme.colors.blueDark};
                `}
              />
              <Text>
                If you are involved with students in mathematics or mathematics related courses at a community college, you can join us!
              </Text>

              <Text>
                If you teach outside of the community college system, you can join as an affiliate member with non-voting privileges.
              </Text>

              <Text>
                <b>Membership Cost :</b> None
              </Text>

              <div
                css={css`
                  position: relative;

                  & > * {
                    transition: 0.12s ease-in-out all;
                  }
                `}
              >
                <form
                  onSubmit={_handleSubmit}
                  css={css`
                    opacity: ${submitSuccessful ? 0 : 1};
                    pointer-events: ${submitSuccessful ? "none" : "auto"};

                    & > * {
                      margin-bottom: ${theme.margin.small};
                    }
                  `}
                >
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder={"Email Address"}
                    disabled={submitSuccessful}
                    css={StyledInput}
                    required
                  />
                  <input
  type="text"
  name="firstName"  // Fix: Match state variable name
  value={firstName}
  onChange={(e) => setFirstName(e.target.value)}
  placeholder="First Name"
  disabled={submitSuccessful}
  css={StyledInput}
/>

<input
  type="text"
  name="lastName"
  value={lastName}
  onChange={(e) => setLastName(e.target.value)}
  placeholder="Last Name"
  disabled={submitSuccessful}
  css={StyledInput}
/>

<input
  type="text"
  name="college"
  value={college}
  onChange={(e) => setCollege(e.target.value)}
  placeholder="College"
  disabled={submitSuccessful}
  css={StyledInput}
/>
                  <button
                    type="submit"
                    value="Send Message"
                    disabled={submitSuccessful}
                    css={css`
                      background: none;
                      border: none;
                      padding: 0;
                      width: 100%;
                    `}
                  >
                    <StyledLink
                      block
                      css={css`
                        margin: 0;
                      `}
                    >
                      Submit
                    </StyledLink>
                  </button>
                </form>
                <div
                  css={css`
                    position: absolute;
                    text-align: center;
                    opacity: ${submitSuccessful ? 1 : 0};
                    pointer-events: ${submitSuccessful ? "auto" : "none"};
                    ${theme.transformCenterY()};
                  `}
                >
                  <CheckIcon
                    css={css`
                      font-size: 96px;
                      color: ${theme.colors.blueDark};
                    `}
                  />
                  <Text h3>Thank you, your sign up request was successful! Please check your email inbox to confirm.</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          css={css`
            background-color: ${theme.colors.whiteAlt};
            padding: 20px;
          `}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <Text>
                  <b>For Further Information Please Contact:</b>
                </Text>
                <Text>
                  Corey Pyne | St. Clair College |{" "}
                  <Link to={`mailto:CPyne@stclaircollege.ca`}>
                    CPyne@stclaircollege.ca <MailIcon />
                  </Link>
                </Text>
              </div>
            </div>
          </div>
        </div>
        <CloseIcon
          onClick={closeModal}
          css={css`
            font-size: 2em;
            color: ${theme.colors.blueDark};
            position: absolute;
            top: 6px;
            right: 6px;
            cursor: pointer;
          `}
        />
      </div>
    </Modal>
  );
}